<template>
  <div>
      <div class="px-6 py-6">
          <h5 class="text-h5 font-weight-bold text-typo">Basic Info</h5>
      </div>
      <div class="px-6 pb-6 pt-0">
          <v-row>
              <v-col cols="6">
                  <v-text-field
                      color="#e91e63"
                      label="Domain name"
                      placeholder="Domain name"
                      class="font-size-input input-style"
                      v-model="form.domain"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-menu
                      ref="showNextDateAnalyseDatePicker"
                      v-model="showNextDateAnalyseDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="form.next_date_analyse"
                              label="Next date of analysis"
                              persistent-hint
                              dense
                              outlined
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="form.next_date_analyse"
                          no-title
                          @input="showNextDateAnalyseDatePicker = false"
                      ></v-date-picker>
                  </v-menu>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="6">
                  <v-select
                      v-model="form.responsible_user_id"
                      :items="users"
                      item-text="email"
                      item-value="id"
                      label="Responsible user"
                      dense
                      outlined
                  ></v-select>
              </v-col>
          </v-row>
      </div>
      <div class="px-6 py-6">
          <h5 class="text-h5 font-weight-bold text-typo">Script's search criterias</h5>
      </div>
      <div class="px-6 pb-6 pt-0">
          <v-row>
              <v-col cols="6">
                  <v-select
                      v-model="form.grouping"
                      :items="groupings"
                      label="Grouping"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-select
                      v-model="form.backlink_type"
                      :items="backlinkTypes"
                      label="Backlink type"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-select
                      v-model="form.search_by"
                      :items="searchBy"
                      label="Search by"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-select
                      v-model="form.filter_subdomains"
                      :items="filterSubdomains"
                      label="Filter subdomains"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-select
                      v-model="form.follow_type"
                      :items="followTypes"
                      label="Follow type"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-select
                      v-model="form.highlight_changes"
                      :items="highlightChanges"
                      label="Highlight changes"
                      dense
                      outlined
                  ></v-select>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                      color="#e91e63"
                      label="Trafic from"
                      placeholder="Trafic from"
                      class="font-size-input input-style"
                      v-model="form.traffic_from"
                      type="number"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                      color="#e91e63"
                      label="Trafic to"
                      placeholder="Trafic to"
                      class="font-size-input input-style"
                      v-model="form.traffic_to"
                      type="number"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                      color="#e91e63"
                      label="DR from"
                      placeholder="DR from"
                      class="font-size-input input-style"
                      v-model="form.dr_from"
                      type="number"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-text-field
                      color="#e91e63"
                      label="DR to"
                      placeholder="DR to"
                      class="font-size-input input-style"
                      v-model="form.dr_to"
                      type="number"
                      outlined
                      dense
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="6">
                  <v-btn
                      color="#cb0c9f"
                      class="
                        font-weight-bolder
                        btn-default
                        bg-gradient-default
                        py-4
                        px-8
                        ms-auto
                        mt-sm-auto mt-4
                      "
                      small
                      @click="createOrEditCompetitor()"
                  >
                      Create
                  </v-btn>
              </v-col>
          </v-row>
      </div>
  </div>
</template>
<script>
import axiosIns from '../../../../plugins/axios';

export default {
  name: "CreateOrEdit",
  components: {},
  data: () => {
      return {
          backlinkTypes: [
              {text: 'Regular links', value: 'regular-links'},
              {text: 'In content', value: 'in-content'},
              {text: 'Image in anchor', value: 'image-in-anchor'},
              {text: 'Redirect', value: 'redirect'},
              {text: 'Canonical', value: 'canonical'},
              {text: 'Frame', value: 'frame'},
          ],
          searchBy: [
              {text: 'Domain', value: 'domain'},
              {text: 'Subdomains', value: 'subdomains'},
              {text: 'Prefix', value: 'prefix'},
              {text: 'Exact', value: 'exact'},
          ],
          filterSubdomains: [
              {text: 'Yes', value: '1'},
              {text: 'No', value: '0'},
          ],
          followTypes: [
              {text: 'Do follow', value: 'dofollow'},
              {text: 'All', value: 'all'},
              {text: 'No follow', value: 'nofollow'},
              {text: 'UGC', value: 'ugc'},
              {text: 'Sponsored', value: 'sponsored'},
          ],
          highlightChanges: [
              {text: '24h', value: '24h'},
              {text: '7d', value: '7d'},
              {text: '30d', value: '30d'},
              {text: '3m', value: '3m'},
              {text: '6m', value: '6m'},
          ],
          groupings: [
              {text: 'All', value: 'all'},
              {text: 'Group similar', value: 'group-similar'},
              {text: 'One per domain', value: 'one-per-domain'},
          ],
          analyseTimeInterval: [
              {value: '86400', text: '1 day'},
              {value: '604800', text: '7 day'},
              {value: '2628000', text: '1 month'},
              {value: '7890000', text: '3 months'},
              // {value: '3600', text: '1 hour'},
              // {value: '300', text: '5 minutes'},
              // {value: '60', text: '1 minute'},
          ],
          form: {
              domain: '',
              analyse_time_interval: '2628000',
              next_date_analyse: '',
              responsible_user_id: '',
              grouping: 'one-per-domain',
              traffic_from: '1000',
              traffic_to: '',
              highlight_changes: '6m',
              follow_type: 'dofollow',
              filter_subdomains: '1',
              search_by: 'domain',
              dr_to: '',
              dr_from: '30',
              backlink_type: 'regular-links',
          },
      };
  },
  computed: {
      users() {
          return this.$store.getters['other/linkbuilders'];
      },
  },
  beforeMount () {
      if (this.$route.params.id) {
          this.loadCompetitor();
      }
  },
  methods: {
      async loadCompetitor() {
          const response = await axiosIns.get('/api/competitors/'+this.$route.params.id);
          this.form = response.data.competitor;
      },
      createOrEditCompetitor() {
          if (this.$route.params.id) {
              this.editCompetitor();
          } else {
              this.createCompetitor();
          }
      },
      editCompetitor() {
          axiosIns.post('/api/competitors/'+this.$route.params.id, this.form)
              .then((response) => {
                  console.log(response);
                  this.$router.push('/competitors');
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
      createCompetitor() {
          axiosIns.post('/api/competitors', this.form)
              .then((response) => {
                  console.log(response);
                  // localStorage.setItem('accessToken', response.data.accessToken);
                  this.$router.push('/competitors');
              })
              .catch((error) => {
                  this.$store.commit('app/SNACKBAR', {
                      show: true,
                      body: error.response.data.message,
                      type: 'error',
                  });
              });
      },
  }
};
</script>
